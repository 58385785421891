<template>
  <div class="test-cards-container">
    <h5>Test Cards</h5>
    <div class="test-card">
      <span>4242 4242 4242 4242 | Exp: 02/26 | CVV: 123</span>
      <i class="fas fa-copy copy-icon" @click="copyToClipboard('4242 4242 4242 4242')"></i>
    </div>
    <div class="test-card">
      <span>5555 5555 5555 4444 | Exp: 03/27 | CVV: 456</span>
      <i class="fas fa-copy copy-icon" @click="copyToClipboard('5555 5555 5555 4444')"></i>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    async copyToClipboard(text) {
      await navigator.clipboard.writeText(text);
      this.$notify({group: 'foo', type: 'success', title: 'Success', text: 'Copied Successfully'});
    }
  }
}
</script>

<style>
.test-cards-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
}

.test-card {
  background: #f8f9fa;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copy-icon {
  cursor: pointer;
  color: #007bff;
}
</style>
