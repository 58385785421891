<template>
  <div class="container">

    <div v-if="(this.activeSuccess || this.saveCardSuccess) && this.showHtml">
      <Receipt :transaction="transactionDetails" :settings="settings" :coupon="coupon"/>
      <p v-if="transactionDetails?.payment_component !== 'MANUAL_INVOICE' && !transactionDetails?.plan?.is_custom_plan && this.activeTimer"
         class="p_label">
        Redirecting in {{
          timerCount
        }}...</p>
    </div>

    <div v-if="this.activeFailed">
      <LottieAnimation ref="anim" :animationData="require('@/assets/lottie/payment-failed.json')" :loop="3"
                       :autoPlay="true"/>
      <p class="p_label">{{ this.errorMsg }}</p>
    </div>

    <Skeleton v-if="!showHtml"></Skeleton>

    <section :show="showOverlay">
      <div class="row justify-content-center" v-if="showHtml && this.activePaymentPage">
        <div class="col-xl-14 w-100">
          <div class="outer-container margin_top p-md-4 p-sm-1">
            <div class="row" :class="{'justify-content-center': isSaveCard}">
              <div class="col-lg-7 pr-lg-5 pl-lg-5" v-if="!isSaveCard">
                <div class="order-summary p-4">
                  <img class="img-fluid mb-3" style="width: 120px"
                       v-if="this.settings?.logo_url" :src="this.settings?.logo_url"
                       alt="logo"/>
                  <h2 class="section-title text-blue font-medium">{{ getHeading }}</h2>
                  <div class="plan-details mt-4">
                    <div class="total-payable">
                      <div class="d-flex justify-content-between align-items-center">
                        <div class="d-flex w-100 justify-content-between align-items-center">
                          <p class="label text-gray">Total payable</p>
                          <span v-if="showDueBadge" class="badge ml-auto payment-due-badge">Payment due</span>
                        </div>

                        <p class="sent-by text-gray mb-4">
                          {{ getSentBy }}
                        </p>
                      </div>

                      <div class="d-flex" style="width: 55%;">
                        <div class="amount">{{ getCurrency + getTotalAmount }}</div>
                        <div class="period text-gray ml-1">{{ getFrequency }}</div>
                      </div>
                    </div>

                    <div class="amount-due mt-1" v-if="getDueDate">
                      <div class="label text-gray">Amount due</div>
                      <div class="date mb-1">{{ getDueDate }}</div>
                    </div>

                    <div class="plan-info mt-4 mb-4">
                      <h6 class="">{{ getTitle }}</h6>
                      <div class="d-flex justify-content-between align-items-center">
                        <p class="plan-desc text-gray">{{ getSubTitle }}</p>
                        <div class="plan-price">{{ getCurrency + getTotalAmount }} {{ getFrequency2 }}</div>
                      </div>
                    </div>

                    <div class="price-breakdown">
                      <div class="price-row" v-for="(item, index) in getItems" :key="index">
                        <span>{{ item.label + ' x ' + item.qty }}</span>
                        <span>{{ getCurrency + item.total }}</span>
                      </div>
                      <div class="price-row sub-total">
                        <span>Sub Total ( 1 item )</span>
                        <span>{{ getCurrency + getAmountWithoutTax }}</span>
                      </div>
                      <div class="price-row">
                        <span>Discount {{ getDiscountPercent }}</span>
                        <span>{{ getCurrency + formatNumber(getDiscountAmount) }}</span>
                      </div>
                      <div class="price-row">
                        <span>Sales Tax ({{ getSalesTaxPercent }}%)</span>
                        <span>{{ getCurrency + getSalesTaxAmount }}</span>
                      </div>
                      <div class="price-row total">
                        <span>Total</span>
                        <span>{{ getCurrency + getTotalAmount }}</span>
                      </div>
                      <div class="next-charge" v-html="getFooter">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-5">
                <div class="payment-section p-4">
                  <h2 class="section-title font-medium">{{ isSaveCard ? 'Add a Payment Card' : 'Payment Details' }}</h2>
                  <h6 v-if="isSaveCard && activePaymentMethod === 'paypal'">We will use this payment method to charge
                    you for future subscription payments.</h6>

                  <div class="payment-methods mb-4" style="display: none">
                    <div class="method-options">
                      <button class="method-btn" :class="{ active: activePaymentMethod === 'card' }"
                              @click="activePaymentMethod = 'card'">
                        <img src="/Mastercard.svg" alt="Credit/Debit Card"/> Credit/Debit Card
                      </button>
                      <button class="method-btn" :class="{ active: activePaymentMethod === 'paypal' }"
                              @click="activePaymentMethod = 'paypal'">
                        <img src="/Paypal.svg" alt="PayPal"/> PayPal
                      </button>
                      <button class="method-btn" :class="{ active: activePaymentMethod === 'upi' }"
                              @click="activePaymentMethod = 'upi'">
                        <img src="/upi.svg" alt="UPI"/> UPI
                      </button>
                    </div>
                  </div>

                  <div v-if="!showContinueWithoutPay">
                    <!-- Card Payment Form -->
                    <div
                        :class="{'show-tab': activePaymentMethod === 'card', 'hide-tab': activePaymentMethod !== 'card'}"
                        class="payment-form">
                      <Stripe v-if="transactionDetails.payment_provider === 'STRIPE'"
                              @on-success="successCallback" @on-loader="loaderCallback"
                              :transaction-details="transactionDetails" ref="stripe"/>
                    </div>

                    <!-- PayPal Payment Form -->
                    <div
                        :class="{'show-tab': activePaymentMethod === 'paypal', 'hide-tab': activePaymentMethod !== 'paypal'}"
                        class="payment-content">
                      <div class="text-center p-4">
                        <Paypal @on-success="successCallback" @on-loader="loaderCallback"
                                @hide-button="hideButton"
                                :transaction-details="transactionDetails" ref="paypal"/>
                      </div>
                    </div>

                    <!-- UPI Payment Form -->
                    <div :class="{'show-tab': activePaymentMethod === 'upi', 'hide-tab': activePaymentMethod !== 'upi'}"
                         class="payment-content">
                      <div class="text-center p-4">
                        <Razorpay @on-success="successCallback" @on-loader="loaderCallback"
                                  :transaction-details="transactionDetails" ref="razorpay"
                                  :settings="settings"/>
                      </div>
                    </div>
                  </div>

                  <DiscountCode v-if="transactionDetails.payment_provider === 'STRIPE' && !isSaveCard"
                                @on-applied="onCouponApplied"
                                :transaction-tsid="transactionTsid"/>

                  <div class="mt-4 terms-text" v-if="transactionDetails.is_mor">
                    This order process is conducted by online reseller & Merchant of Record, Stykite. Your data will
                    be
                    shared with {{ this.settings?.merchant_name }} for order fulfilment. Stykite, Inc, Suite 484,
                    2093A,
                    Philadelphia Pike,
                    Claymont,
                    Delaware 19703
                    <a target="_blank" :href="tandc">Terms & Conditions</a>
                    &nbsp;
                    <a target="_blank" :href="privacyPolicy">Privacy Policy</a>
                  </div>

                  <div class="mt-4" v-if="showPayButton">
                    <div class="mt-4 text-center">
                      <button :disabled="showLoader" class="pay-button" @click="pay">
                        <b-spinner class="text-white" v-if="showLoader" small label="Spinning"></b-spinner>
                        {{
                          transactionDetails?.payment_component === 'SAVE_CARD' ? 'Save Card' :
                              'Continue and Pay ' + (getCurrency) + (getTotalAmount)
                        }}
                      </button>
                    </div>
                  </div>

                  <div class="mt-4" v-if="showContinueWithoutPay">
                    <div class="mt-4 text-center">
                      <button :disabled="showLoader" class="pay-button" @click="continueWithoutPay">
                        <b-spinner class="text-white" v-if="showLoader" small label="Spinning"></b-spinner>
                        {{
                          'Continue ' + (getCurrency) + (getTotalAmount)
                        }}
                      </button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <TestCard v-if="showHtml && this.activePaymentPage && !transactionDetails.is_live"/>

  </div>
</template>

<script>
import {useVuelidate} from '@vuelidate/core'
import LottieAnimation from 'lottie-web-vue'
import axios from 'axios'
import Receipt from "../components/Receipt/index.vue";
import {setValueWithMissingObject} from "@/util/commonUtil";
import moment from "moment/moment";
import {calculateStairCasePrice, calculateTieredPrice, calculateVolumnPrice} from "@/util/usageUtil";
import Stripe from "@/components/Gateway/Stripe.vue";
import Paypal from "@/components/Gateway/Paypal.vue";
import Skeleton from "@/components/Loader/Skeleton.vue";
import Razorpay from "@/components/Gateway/Razorpay.vue";
import TestCard from "@/components/TestCard";
import DiscountCode from "@/components/DiscountCode";

export default {
  setup() {
    return {v$: useVuelidate()}
  },
  data() {
    return {
      showHtml: false,
      stripeElements: null,
      showOverlay: false,
      activeTimer: false,
      activeSuccess: false,
      activeFailed: false,
      activePaymentPage: true,
      saveCardSuccess: false,
      timerCount: 4,
      errorMsg: null,
      tandc: process.env.VUE_APP_TERMS_AND_CONDITIONS,
      privacyPolicy: process.env.VUE_APP_PRIVACY_POLICY,
      transactionTsid: null,
      transactionDetails: null,
      settings: null,
      activePaymentMethod: 'card',
      showLoader: false,
      discountedAmount: null,
      discount: null,
      discountType: null,
      couponFrequency: null,
      coupon: null,
      showPayButton: true,
      showContinueWithoutPay: false,
    }
  },
  components: {
    Razorpay,
    Skeleton,
    Paypal,
    Stripe,
    LottieAnimation,
    Receipt,
    TestCard,
    DiscountCode
  },
  validations() {
    return {}
  },
  computed: {
    getHeading() {
      if (this.transactionDetails?.payment_component === 'PLAN'
          || this.transactionDetails?.payment_component === 'ADD_ON') {
        return "Order Summary"
      }
      if (this.transactionDetails?.payment_component === 'MANUAL_INVOICE'
          || this.transactionDetails?.payment_component === 'INVOICE_OVERDUE_PAY') {
        return "Invoice"
      }
      return "Invoice Summary"
    },
    getTotalAmount() {
      if (this.discountedAmount !== null && this.discountedAmount !== undefined) {
        return this.discountedAmount ? (this.discountedAmount / 100).toFixed(2) : '0.00'
      }
      return this.transactionDetails?.amount ? (this.transactionDetails?.amount / 100).toFixed(2) : '0.00'
    },
    getCurrency() {
      return this.transactionDetails?.currency_symbol
    },
    getFrequency() {
      if (this.transactionDetails?.payment_component === 'PLAN')
        return `per ${this.getFrequencyLabel(this.transactionDetails.plan_type)}`
      if (this.transactionDetails?.payment_component === 'INVOICE_OVERDUE_PAY' && this.transactionDetails.plan) {
        return `per ${this.getFrequencyLabel(this.transactionDetails.plan_type)}`
      }
      return ''
    },
    getFrequency2() {
      if (this.transactionDetails?.payment_component === 'PLAN')
        return `/${this.getFrequencyLabel(this.transactionDetails.plan_type)}`
      if (this.transactionDetails?.payment_component === 'INVOICE_OVERDUE_PAY' && this.transactionDetails.plan) {
        return `/${this.getFrequencyLabel(this.transactionDetails.plan_type)}`
      }
      return ''
    },
    getDueDate() {
      if (this.transactionDetails?.payment_component === 'MANUAL_INVOICE') {
        return this.formatDate(this.transactionDetails.invoice.due_date)
      }
      if (this.transactionDetails?.payment_component === 'INVOICE_OVERDUE_PAY' && this.transactionDetails.invoice) {
        return this.formatDate(this.transactionDetails.invoice.due_date)
      }
      return ''
    },
    showDueBadge() {
      if (this.transactionDetails?.payment_component === 'MANUAL_INVOICE') {
        return this.transactionDetails.invoice.due_date < new Date().getTime()
      }
      if (this.transactionDetails?.payment_component === 'INVOICE_OVERDUE_PAY' && this.transactionDetails.invoice) {
        return this.transactionDetails.invoice.due_date < new Date().getTime()
      }
      return false
    },
    getTitle() {
      if (this.transactionDetails?.payment_component === 'PLAN') {
        return this.transactionDetails.plan.name
      }
      if (this.transactionDetails?.payment_component === 'MANUAL_INVOICE') {
        return this.transactionDetails.invoice.name
      }
      if (this.transactionDetails?.payment_component === 'ADD_ON') {
        return this.transactionDetails.addon.name
      }
      if (this.transactionDetails?.payment_component === 'SAVE_CARD' && this.transactionDetails.plan) {
        return this.transactionDetails.plan.name
      }
      if (this.transactionDetails?.payment_component === 'INVOICE_OVERDUE_PAY' && this.transactionDetails.plan) {
        return this.transactionDetails.plan.name
      }
      return ''
    },
    getSubTitle() {
      if (this.transactionDetails?.payment_component === 'PLAN') {
        return this.transactionDetails.plan.description
      }
      if (this.transactionDetails?.payment_component === 'ADD_ON') {
        return this.transactionDetails.addon.description
      }
      if (this.transactionDetails?.payment_component === 'SAVE_CARD' && this.transactionDetails.plan) {
        return this.transactionDetails.plan.description
      }
      if (this.transactionDetails?.payment_component === 'INVOICE_OVERDUE_PAY' && this.transactionDetails.plan) {
        return this.transactionDetails.plan.description
      }
      return ''
    },
    getSalesTaxPercent() {
      return this.transactionDetails.sales_tax ? this.transactionDetails.sales_tax : 0
    },
    getAmountWithoutTax() {
      if (this.transactionDetails.sales_tax && this.transactionDetails?.amount) {
        const total = this.transactionDetails?.amount
        return this.amountWithoutTax(total, this.transactionDetails.sales_tax)
      }
      return this.transactionDetails?.amount ? (this.transactionDetails?.amount / 100).toFixed(2) : (0).toFixed(2)
    },
    getSalesTaxAmount() {
      if (this.transactionDetails.sales_tax && this.transactionDetails?.amount) {
        const total = this.transactionDetails?.amount
        const subTotal = (total * 100) / (100 + this.transactionDetails.sales_tax)
        return ((subTotal / 100) * (this.transactionDetails.sales_tax / 100)).toFixed(2)
      }
      return (0).toFixed(2)
    },
    getDiscountPercent() {
      if (this.discountType && this.discount) {
        if (this.discountType === 'PERCENTAGE') {
          return `(${this.discount}%)`
        } else {
          return `(-${this.discount})`
        }
      }
      return `(0%)`
    },
    getDiscountAmount() {
      if (this.discountType && this.discount) {
        const total = this.transactionDetails?.amount
        if (this.discountType === 'PERCENTAGE') {
          return ((total * (this.discount / 100)) / 100)
        } else {
          return this.discount
        }
      }
      return 0
    },
    getFooter() {
      if (this.transactionDetails?.payment_component === 'PLAN' && this.transactionDetails.plan &&
          (this.transactionDetails.plan.cycles == null || this.transactionDetails.plan.cycles > 1)) {
        let amount = this.transactionDetails?.amount ? (this.transactionDetails?.amount / 100).toFixed(2) : '0.00'
        if ((this.couponFrequency == null || this.couponFrequency > 1) && (this.discountedAmount !== null && this.discountedAmount !== undefined)) {
          amount = this.discountedAmount
        }
        return '<i class="fas fa-info-circle"></i>' +
            'Next charge on ' + this.getNextDate(this.transactionDetails.plan_type) +
            '<span class="amount">' + this.getCurrency + amount + '</span>'
      }
      return ''
    },
    getSentBy() {
      if (this.transactionDetails?.payment_component === 'PLAN' && this.transactionDetails.plan &&
          this.transactionDetails.plan.is_custom_plan) {
        if (this.settings?.merchant_name)
          return 'Sent By ' + this.settings?.merchant_name
      }
      return ''
    },
    getItems() {
      const tax = this.transactionDetails.sales_tax || 0
      const planType = this.transactionDetails?.plan_type
      const prices = this.transactionDetails?.plan?.price || []
      const items = []
      const amount = this.getFixedAmount(prices, this.transactionDetails?.plan_type)
      if (amount) {
        items.push({label: this.transactionDetails.plan.name, qty: 1, total: this.amountWithoutTax(amount * 100, tax)})
      }
      const usages = this.transactionDetails?.invoice?.usages || []
      for (let i = 0; i < usages.length; i++) {
        const usage = usages[i]
        const meter = prices.find(p => p.tsid === usage.meter_item_tsid)
        const pricing_model = meter.pricing_model
        let total = 0
        switch (pricing_model) {
          case 'STAIR':
            total = calculateStairCasePrice(usage.qty, meter.frequency[this.planTypeToFrequency(planType)])
            break
          case 'TIERED':
            total = calculateTieredPrice(usage.qty, meter.frequency[this.planTypeToFrequency(planType)])
            break
          case 'VOLUME':
            total = calculateVolumnPrice(usage.qty, meter.frequency[this.planTypeToFrequency(planType)])
            break
        }
        total = this.amountWithoutTax(total * 100, tax)
        items.push({label: meter?.label, qty: usage.qty, total: total})
      }

      if (this.transactionDetails.payment_component === 'MANUAL_INVOICE') {
        const invoiceItems = this.transactionDetails?.invoice?.manual_invoice_items || []
        for (let i = 0; i < invoiceItems.length; i++) {
          const item = invoiceItems[i]
          items.push({
            label: item?.description,
            qty: item.qty,
            total: this.amountWithoutTax(item.qty * item.price * 100, tax)
          })
        }
      }

      return items
    },
    isSaveCard() {
      return this.transactionDetails?.payment_component === 'SAVE_CARD'
    }
  },
  async mounted() {
    window.addEventListener('keyup', this.handleKeyPress);
    this.showOverlay = true
    this.transactionTsid = this.$route.params.transactionTsid

    //This is for resultId first test transaction raised before code refactoring
    //Remove once this transaction is SUCCESS
    if (this.transactionTsid === 'pi_3P3JOXDD7hrq7AJL1qQyCpB7_secret_jGPi6oyC3wJ9DucDTGs8jWyIH') {
      this.transactionTsid = 'TRN-0FNZGEBRFCXNP'
    }

    this.transactionDetails = await this.getTransactionDetails();

    // decide gateway
    const gateway = this.transactionDetails.payment_provider
    if (gateway === 'STRIPE') {
      this.activePaymentMethod = "card"
    }
    if (gateway === 'PAYPAL') {
      this.activePaymentMethod = "paypal"
    }
    if (gateway === 'RAZORPAY') {
      this.activePaymentMethod = "upi"
    }

    //if no country or zip code then auto-detect from ip
    if (!this.transactionDetails?.customer?.billing_address?.country_code ||
        !this.transactionDetails?.customer?.billing_address?.postal_code) {
      await this.autoDetectCountryAndPostalCode()
    }

    await this.getSettings();
    this.showHtml = true

    // show success page if transaction already completed
    if (this.transactionDetails.status === 'SUCCESS') {
      this.showOverlay = false
      this.activePaymentPage = false
      if (this.transactionDetails?.payment_component === 'SAVE_CARD') {
        this.saveCardSuccess = true
      } else {
        this.activeSuccess = true
      }
      return
    }


    /*const options = {mode: 'billing'}
    const addressElement = this.stripeElements.create('address', options)
    addressElement.mount('#address-element')*/

    this.showOverlay = false
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.handleKeyPress);
  },
  watch: {
    timerCount: {
      handler(value) {
        if (this.activeTimer) {
          if (value > 0) {
            setTimeout(() => {
              this.timerCount--
            }, 1000)
          } else {
            if (this.transactionDetails?.payment_component !== 'MANUAL_INVOICE' && !this.transactionDetails?.plan?.is_custom_plan) {
              window.location = this.settings?.redirect_url_after_payment
            }
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    async continueWithoutPay() {
      this.showLoader = true
      this.showOverlay = true
      try {
        const payload = {
          "transaction_tsid": this.transactionTsid,
          "coupon": this.coupon?.coupon
        }
        await axios.post(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/payment/success`, payload)
        this.successCallback()
      } catch (error) {
        let errorMsg = ''
        if (error.response) {
          errorMsg = error.response?.data?.error?.message || 'Something went wrong. Please try again.'
        }
        this.$notify({group: 'foo', type: 'error', title: 'Error', text: errorMsg});
      }
    },
    async pay() {
      this.showLoader = true
      this.showOverlay = true
      const gateway = this.transactionDetails.payment_provider
      if (gateway === 'STRIPE') {
        await this.$refs.stripe.pay()
      }
      if (gateway === 'PAYPAL') {
        await this.$refs.paypal.pay()
      }
      if (gateway === 'RAZORPAY') {
        await this.$refs.razorpay.pay()
      }
    },
    successCallback() {
      this.showLoader = false
      this.activePaymentPage = false
      if (this.transactionDetails?.payment_component === 'SAVE_CARD') {
        this.saveCardSuccess = true
      } else {
        this.activeSuccess = true
      }
      this.activeTimer = true
      this.timerCount--
    },
    hideButton() {
      this.showPayButton = false
    },
    loaderCallback(data) {
      this.showLoader = data
    },
    async getTransactionDetails() {
      try {
        const {data} = await axios.get(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/public/transaction/${this.transactionTsid}`)
        return data.value
      } catch (e) {
        console.log(e)
      }
      return null
    },
    async getSettings() {
      try {
        const {data} = await axios.get(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/company/${this.transactionDetails?.company_tsid}/country/${this.transactionDetails?.country_tsid}/settings?type=WORKFLOW_SETTINGS`)
        if (data && data.value) {
          this.settings = {...data.value.setting}
        }
      } catch (e) {
        console.log(e)
      }
    },
    async autoDetectCountryAndPostalCode() {
      try {
        const {data} = await axios.get(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/ip2location`)
        if (data && data.value) {
          const countryCode = data.value.countryCode
          const zip = data.value.zip
          if (!this.transactionDetails?.customer?.billing_address?.country_code) {
            setValueWithMissingObject(this.transactionDetails, 'customer.billing_address.country_code', countryCode)
          }
          if (!this.transactionDetails?.customer?.billing_address?.postal_code) {
            setValueWithMissingObject(this.transactionDetails, 'customer.billing_address.postal_code', zip)
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    getFrequencyLabel(frequency) {
      switch (frequency) {
        case 'MONTHLY':
          return 'month'
        case 'WEEKLY':
          return 'week'
        case 'YEARLY':
          return 'year'
      }
      return ''
    },
    getNextDate(frequency) {
      switch (frequency) {
        case 'MONTHLY':
          return moment().add(30, 'days').format('MMMM DD, YYYY');
        case 'WEEKLY':
          return moment().add(7, 'days').format('MMMM DD, YYYY');
        case 'YEARLY':
          return moment().add(365, 'days').format('MMMM DD, YYYY');
      }
      return ''
    },
    formatDate(date) {
      return moment(date).format('MMMM DD, YYYY');
    },
    getFixedAmount(prices, frequency) {
      for (let i = 0; i < prices.length; i++) {
        const price = prices[i]
        const mode = price.mode
        const pricing_model = price.pricing_model
        const freq = price.frequency
        if (mode === 'POSTPAID' && pricing_model === 'FIXED') {
          if (frequency === 'MONTHLY') {
            return freq.month[0].price
          }
          if (frequency === 'YEARLY') {
            return freq.year[0].price
          }
          if (frequency === 'WEEKLY') {
            return freq.week[0].price
          }
        }
      }
      return null
    },
    planTypeToFrequency(planType) {
      switch (planType) {
        case 'MONTHLY':
          return 'month'
        case 'YEARLY':
          return 'year'
        case 'WEEKLY':
          return 'week'
      }
      return null
    },
    amountWithoutTax(total, tax) {
      const subTotal = (total * 100) / (100 + tax)
      return (subTotal / 100).toFixed(2)
    },
    handleKeyPress(event) {
      if (event.key === 'Enter') {
        this.pay();
      }
    },
    onCouponApplied(couponData) {
      this.discountedAmount = couponData.discounted_amount
      this.discountType = couponData.discount_type
      this.discount = couponData.discount
      this.couponFrequency = couponData.frequency
      this.coupon = {
        discount: this.discount,
        discount_type: this.discountType,
        discounted_amount: this.discountedAmount,
        coupon: couponData.coupon
      }
      if (this.discountedAmount === 0) {
        this.showPayButton = false
        this.showContinueWithoutPay = true
      } else {
        this.showPayButton = true
        this.showContinueWithoutPay = false
      }
    },
    formatNumber(num) {
      if (num) {
        return num.toFixed(2)
      }
      return num
    }
  }
}
</script>

<style scoped>
.container {
  color: #1A1A1A;
  /*min-height: 90vh;*/
  padding: 2rem;
}

.text-blue {
  color: #4B5FFF;
}

.text-gray {
  color: #64748B;
}

.order-summary {
  background: #FFFFFF;
}

.total-payable {
  .amount {
    font-size: 32px;
    font-weight: 600;
    line-height: 1.2;
  }

  .period {
    font-size: 14px;
  }
}

.sent-by {
  font-size: 14px;
  font-style: italic;
}

.amount-due {
  display: flex;
  align-items: center;
  gap: 10px;

  .label {
    font-size: 14px;
    margin-bottom: 4px;
  }

  .date {
    font-size: 14px;
    font-weight: 500;
  }
}

.plan-info {
  border-bottom: 1.5px solid #E2E8F0;
  padding-bottom: 12px;

  .plan-type {
    font-size: 14px;
    margin-bottom: 4px;
  }

  .plan-desc {
    font-size: 16px;
    margin-bottom: 4px;
  }

  .plan-price {
    font-size: 16px;
    font-weight: 500;
  }
}

.price-breakdown {
  margin-top: 10px;
  margin-left: 40px;
}

.price-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  font-size: 14px;
  color: #64748B;

  &.sub-total {
    font-weight: 500;
    color: #1A1A1A;
    border-bottom: 1.5px solid #E2E8F0;
    padding-bottom: 12px;
  }

  &.total {
    font-weight: 600;
    font-size: 16px;
    border-block: 1.5px solid #E2E8F0;
    padding: 12px 0;
    margin-top: 12px;
    color: #1A1A1A;
  }
}

.next-charge {
  color: #64748B;
  font-size: 14px;
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: 8px;

  .amount {
    margin-left: auto;
  }
}

.payment-section {
  background: #FFFFFF;
}

.method-options {
  display: flex;
  gap: 12px;
  padding: 4px;
  background: #F1F5F9;
  border-radius: 8px;
}

.method-btn {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 8px;
  border: none;
  background: transparent;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;

  &.active {
    background: #FFFFFF;
  }

  img {
    width: 24px;
    height: 24px;
  }
}

.form-group {
  label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
  }
}

.form-control {
  width: 100%;
  border: 1.5px solid #E2E8F0;
  border-radius: 8px;
  font-size: 14px;

  &::placeholder {
    color: #9CA3AF;
  }

  &:focus {
    outline: none;
    border-color: #4B5FFF;
  }
}

.pay-button {
  width: 100%;
  background: #5068F0;
  color: #FFFFFF;
  border: none;
  border-radius: 8px;
  padding: 14px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: #3B4FEF;
  }

  &:disabled {
    background: #c7cdfd;
  }
}

.user-info {
  font-size: 14px;
  color: #1A1A1A;
  margin-right: 8px;
}

.sign-out {
  font-size: 14px;
  color: #4B5FFF;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.terms-text {
  color: #64748B;
  font-size: 13px;
  line-height: 1.5;
  margin: 24px 0;
}

.terms-link,
.privacy-link {
  color: #4B5FFF;
  text-decoration: none;
  margin-left: 4px;

  &:hover {
    text-decoration: underline;
  }
}

.input-with-icon {
  position: relative;
}

.input-with-icon i {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #64748B;
}

.input-with-icon input,
.input-with-icon select {
  padding-left: 40px;
}

.font-medium {
  font-size: 24px;
}

.show-tab {
  display: block;
}

.hide-tab {
  display: none;
}

.payment-content {
  border: 1px #E6E6E6 solid;
  border-radius: 8px;
}

.p_label {
  text-align: center;
}

.payment-due-badge {
  color: #CA8A04;
  background-color: #FEF9C3;
  font-size: 14px;
  border: 1px solid #CA8A04;
  border-radius: 29px;
  padding: 5px 12px;
  font-weight: 400
}

</style>
