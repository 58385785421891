<template>
  <div id="app">
    <notifications group="foo"/>
    <Navigation/>
  </div>
</template>


<script>
import Navigation from './components/navigation'

export default {
  components: {
    Navigation
  }
}

</script>

<style>
body {
  font-family: 'Geist', sans-serif !important;
}

body, .btn, .navbar, .form-control, .container {
  font-family: 'Geist', sans-serif !important;
}
</style>
