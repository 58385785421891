<template>
  <div id="payment-element"></div>
</template>

<script>
import Vue from 'vue'
import {StripePlugin} from '@vue-stripe/vue-stripe'
import axios from "axios";

export default {
  props: {
    transactionDetails: Object
  },
  methods: {
    async getStripKey() {
      const {data} = await axios.get(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/public/company/${this.transactionDetails?.company_tsid}/country/${this.transactionDetails?.country_tsid}/stripe`)
      if (data && data.value) {
        return data.value
      }
      return null
    },
    async pay() {
      let result = null
      const paymentMethod = {
        billing_details: {
          name: (`${this.transactionDetails?.customer?.first_name} ${this.transactionDetails?.customer?.last_name}`).trim() || '',
          email: this.transactionDetails?.customer?.email || '',
          address: {
            country: this.transactionDetails?.customer?.billing_address?.country_code,
            postal_code: this.transactionDetails?.customer?.billing_address?.postal_code
          }
        }
      };
      if (this.transactionDetails?.payment_component === 'SAVE_CARD') {
        result = await this.$stripe.confirmSetup({
          elements: this.stripeElements,
          confirmParams: {payment_method_data: paymentMethod},
          redirect: 'if_required'
        })
      } else {
        result = await this.$stripe.confirmPayment({
          elements: this.stripeElements,
          confirmParams: {payment_method_data: paymentMethod},
          redirect: 'if_required'
        })
      }
      if (!result || result.error) { //Failed
        if ('validation_error' !== result.error.type) {
          this.errorMsg = result.error.message
          this.$notify({group: 'foo', type: 'error', title: 'Error', text: this.errorMsg});
        }
        this.$emit('on-loader', false)
        return false
      } else { //Success
        this.$emit('on-success')
        return true
      }
    },
  },
  async mounted() {
    //stripe init
    const clientSecret = this.transactionDetails.gateway_reference_id
    const stripe = await this.getStripKey();
    const soptions = {
      pk: stripe.pk,
    }
    if (stripe.account_id) {
      soptions['stripeAccount'] = stripe.account_id
    }
    Vue.use(StripePlugin, soptions)

    const appearance = {
      theme: 'stripe',
    }
    this.stripeElements = this.$stripe.elements({appearance, clientSecret})
    const paymentElementOptions = {
      layout: 'accordion',
      fields: {
        billingDetails: {
          email: 'never',
          name: 'never',
          address: {
            country: 'never',
            postalCode: 'never'
          }
        }
      }
    }
    const paymentElement = this.stripeElements.create('payment', paymentElementOptions)
    paymentElement.mount('#payment-element')
  }
}
</script>
