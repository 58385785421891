<template>
  <div class="container" style="max-width: fit-content">
    <b-overlay :show="showOverlay" rounded="sm">
      <div class="pt-5 pb-5">
        <b-row class="p-4 row-border">
          <b-col cols="12">
            <h1>
              <svg height="38px" version="1.1" viewBox="0 0 18 18" width="38px" xmlns="http://www.w3.org/2000/svg"
                   xmlns:xlink="http://www.w3.org/1999/xlink"><title></title>
                <desc></desc>
                <defs></defs>
                <g fill="none" fill-rule="evenodd" id="Page-1" stroke="none" stroke-width="1">
                  <g fill="#000000" id="Core" transform="translate(-549.000000, -45.000000)">
                    <g id="check-box-outline" transform="translate(549.000000, 45.000000)">
                      <path
                          d="M4.9,7.1 L3.5,8.5 L8,13 L18,3 L16.6,1.6 L8,10.2 L4.9,7.1 L4.9,7.1 Z M16,16 L2,16 L2,2 L12,2 L12,0 L2,0 C0.9,0 0,0.9 0,2 L0,16 C0,17.1 0.9,18 2,18 L16,18 C17.1,18 18,17.1 18,16 L18,8 L16,8 L16,16 L16,16 Z"
                          id="Shape"></path>
                    </g>
                  </g>
                </g>
              </svg>
              {{ getSuccessMessage() }}
            </h1>
            <h3>{{ getSubHeading() }}</h3>
          </b-col>
        </b-row>
        <b-row class="p-4 row-border">
          <b-col cols="12" sm="3" class="logo-container" v-if="this.settings?.logo_url">
            <img class="img-fluid" style="width: 120px"
                 :src="this.settings?.logo_url" alt="logo"/>
          </b-col>
          <b-col cols="12" sm="9">
            <h3 class="ml-2">Transaction Details</h3>
            <b-table-simple borderless>
              <b-tbody>
                <b-tr>
                  <b-td>Transaction Date</b-td>
                  <b-td>{{ getDateInStrFormat(transaction?.created_at) }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td>Transaction ID</b-td>
                  <b-td>{{ trimTsids(transaction?.tsid) }}</b-td>
                </b-tr>
                <b-tr v-if="transaction?.payment_component === 'MANUAL_INVOICE'">
                  <b-td>Invoice ID</b-td>
                  <b-td>{{ trimTsids(transaction?.transaction_for) }}</b-td>
                </b-tr>
                <b-tr v-if="transaction?.payment_component === 'MANUAL_INVOICE' && transaction.status === 'SUCCESS'">
                  <b-td>Amount</b-td>
                  <b-td>{{
                      transaction?.currency_symbol
                    }}{{ (transaction?.amount ? (transaction?.amount / 100).toFixed(2) : '') }}
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-col>
        </b-row>
        <b-row class="p-4 row-border">
          <b-col cols="12">
            <h5 class="mb-3" v-if="transaction?.payment_component !== 'SAVE_CARD'">An email has been sent with the
              invoice.</h5>
            <div v-if="transaction?.payment_component === 'MANUAL_INVOICE'">
              <h5 class="mb-1">You can download the Invoice from below.</h5>
              <h6 class="mb-3">
                <b-link @click="downloadInvoice()">Download Invoice</b-link>
              </h6>
            </div>
            <h6 class="mb-3" v-if="settings?.merchant_support_email"><a
                :href="'mailto:'+settings.merchant_support_email">Have a question? Write to
              {{ settings.merchant_support_email }}</a></h6>
            <h6 class="mb-3 font-smaller"><a href="mailto:support@stykite.com">Alternatively you can write to
              support@stykite.com</a></h6>
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </div>
</template>


<script>
import moment from 'moment/moment'
import axios from "axios";

export default {
  props: {
    transaction: Object,
    settings: Object,
    coupon: Object
  },
  data() {
    return {
      showOverlay: false,
    }
  },
  watch: {},
  methods: {
    getSuccessMessage() {
      if (this.transaction?.payment_component === 'MANUAL_INVOICE' && this.transaction?.status === 'SUCCESS') {
        return 'This invoice is already paid'
      }
      if (this.transaction?.payment_component === 'SAVE_CARD') {
        return 'Payment method update successful!'
      }
      return 'Thank you for the payment!'
    },
    getSubHeading() {
      if (this.transaction?.payment_component === 'MANUAL_INVOICE' && this.transaction?.status === 'SUCCESS') {
        return ''
      }
      if (this.transaction?.payment_component !== 'SAVE_CARD') {
        return `Your payment of ${this.transaction?.currency_symbol}${(this.getAmount() / 100).toFixed(2)} was successful`
      }
      return ''
    },
    getAmount() {
      const coupon = this.transaction.coupon || this.coupon;
      if (coupon) {
        const discountType = coupon.discount_type;
        const discount = coupon.discount;
        if (discountType === 'PERCENTAGE') {
          return (this.transaction.amount - this.transaction.amount * (discount / 100)).toFixed(2);
        } else {
          return (this.transaction.amount - discount * 100).toFixed(2);
        }
      }
      return this.transaction.amount;
    },
    getDateInStrFormat(date) {
      if (!date) {
        return ''
      }
      return moment(new Date(date)).format('MMM DD, YYYY HH:mm')
    },
    async downloadInvoice() {
      this.showOverlay = true
      try {
        let url = `${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/public/company/${this.transaction?.company_tsid}/country/${this.transaction?.country_tsid}/invoice/${this.transaction?.transaction_for}/download`
        const {data} = await axios.get(url, {responseType: "blob",});
        await this.downloadPdfFiles(data, this.transaction.tsid);
      } catch (e) {
        console.error(e)
      } finally {
        this.showOverlay = false
      }
    },
    async downloadPdfFiles(data, fileName) {
      const href = URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName + ".pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    },
    trimTsids(tsid) {
      if (!tsid) return ''
      return tsid.substr(4)
    }
  },
  computed: {},
  async mounted() {
  }
}
</script>


<style scoped>

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.row-border {
  border: black 1px solid;
}

.font-smaller {
  font-size: smaller;
}

</style>
