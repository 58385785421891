<template>
  <div class="">
    <div class="pt-4">
      <div class="input-group">
        <input type="text" v-model="coupon" class="form-control p-3" placeholder="Enter discount code">
        <div class="input-group-append">
          <button :disabled="showLoader" class="btn btn-primary" @click="applyCoupon">
            <b-spinner class="text-white" v-if="showLoader" small label="Spinning"></b-spinner>
            Apply
          </button>
        </div>
      </div>
      <div v-if="discountedAmount" class="alert alert-success mt-3">
        Discount "<span>{{ coupon }}</span>" applied!
        <button class="close" @click="removeCoupon">&times;</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    transactionTsid: String
  },
  data() {
    return {
      showLoader: false,
      coupon: '',
      discountedAmount: null
    }
  },
  methods: {
    async applyCoupon() {
      if (!this.coupon) {
        return
      }
      this.showLoader = true
      try {
        const payload = {
          "coupon": this.coupon,
          "transaction_tsid": this.transactionTsid
        }
        const {data} = await axios.post(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/public/coupon/apply`, payload)
        if (data.value) {
          this.discountedAmount = (data.value.discounted_amount / 100).toFixed(2)
          const v = {
            ...data.value,
            coupon: this.coupon,
          }
          this.$emit('on-applied', v)
        }
      } catch (e) {
        this.$notify({
          group: 'foo',
          type: 'error',
          title: 'Error',
          text: e.response?.data?.error?.message || 'Error applying coupon code'
        });
        this.discountedAmount = null
        this.$emit('on-applied', {
          "discount": 0.0,
          "discount_type": "PERCENTAGE",
          "discounted_amount": null,
          coupon: this.coupon,
        })
      } finally {
        this.showLoader = false
      }
    },
    async removeCoupon() {
      this.showLoader = true
      try {
        const payload = {
          "coupon": this.coupon,
          "transaction_tsid": this.transactionTsid
        }
        const {data} = await axios.post(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/public/coupon/remove`, payload)
        if (data.value) {
          this.coupon = ''
          this.discountedAmount = null
          this.$emit('on-applied', {
            "discount": 0.0,
            "discount_type": "PERCENTAGE",
            "discounted_amount": null,
            frequency: null
          })
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.showLoader = false
      }
    }
  }
}
</script>
