<template>
  <div></div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    transactionDetails: Object,
    settings: Object,
  },
  data() {
    return {
      key: null
    }
  },
  methods: {
    async getRazorpayKey() {
      const {data} = await axios.get(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/public/company/${this.transactionDetails?.company_tsid}/country/${this.transactionDetails?.country_tsid}/razorpay`)
      if (data && data.value) {
        return data.value
      }
      return null
    },
    async pay() {
      this.showOverlay = true
      const razorpayOrderId = this.transactionDetails.gateway_reference_id;
      const options = {
        key: this.key,
        "name": this.settings?.merchant_name,
        "description": "",
        "image": this.settings?.logo_url,
        "prefill": {
          "email": this.transactionDetails?.customer?.email,
          "contact": this.transactionDetails?.customer?.phone,
        },
        "notes": {},
        recurring: '1',
        "theme": {
          "color": "#F37254"
        },
        "order_id": `${razorpayOrderId}`,
        "customer_id": this.transactionDetails?.gateway_customer_id,
      }
      if (this.transactionDetails?.payment_component === 'PLAN' ||
          this.transactionDetails?.payment_component === 'ADD_ON' ||
          this.transactionDetails?.payment_component === 'SAVE_CARD') {
        //options['config'] = this.razorpayOlyCardAndUPI()
      }
      options.theme.image_padding = false;
      options.handler = this.onPaymentComplete;
      options.modal = {
        ondismiss: this.onModalClose,
        escape: true,
        backdropclose: false
      };
      const rzp = new window.Razorpay(options);
      rzp.open();
    },
    onPaymentComplete(res) {
      const razorpayOrderId = this.transactionDetails.gateway_reference_id;
      console.log(res.razorpay_payment_id, razorpayOrderId, res.razorpay_signature)
      this.$emit('on-success')
    },
    onModalClose() {
      this.$emit('on-loader', false)
    },
    razorpayOlyCardAndUPI() {
      return {
        display: {
          blocks: {
            banks: {
              name: 'Pay via UPI or Card',
              instruments: [
                {
                  method: 'upi'
                },
                {
                  method: 'card'
                }
              ],
            },
          },
          sequence: ['block.banks'],
          preferences: {
            show_default_blocks: false,
          },
        },
      }
    },
  },
  async mounted() {
    this.key = await this.getRazorpayKey();
  }
}
</script>
