<template>
  <div>
    <div id="paypal-button" v-if="!isSaveCard"></div>
    <div class="mt-2 mb-2 text-center" v-if="!isSaveCard && cardEligible">or</div>
    <div id="card-form" class="card_container">
      <div id="card-name-field-container"></div>
      <div id="card-number-field-container"></div>
      <div id="card-expiry-field-container"></div>
      <div id="card-cvv-field-container"></div>
      <button id="multi-card-field-button" style="display: none" class="btn_call btn_call_inner" type="button">
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    transactionDetails: Object
  },
  data() {
    return {
      transactionTsid: null,
      paypalPaymentMode: 'WALLET',
      cardEligible: false,
    }
  },
  computed: {
    isSaveCard() {
      return this.transactionDetails && this.transactionDetails.amount === 0
          && this.transactionDetails.payment_component === 'SAVE_CARD'
    }
  },
  methods: {
    paypalSetLoaded() {
      let cardField = null;
      if (this.isSaveCard) {
        cardField = window.paypal.CardFields({
          createVaultSetupToken: this.createVaultSetupToken,
          onApprove: this.createVaultPaymentToken,
        });
      } else {
        window.paypal
            .Buttons({
              style: {
                layout: 'horizontal',
                color: 'gold',
                shape: 'pill',
                label: 'pay',
                tagline: false
              },
              createOrder: this.createOrder,
              onApprove: this.onApprove,
            })
            .render('#paypal-button');
        cardField = window.paypal.CardFields({
          createOrder: this.createOrder,
          onApprove: this.onApprove,
        });
      }

      // Render each field after checking for eligibility
      console.log('cardField.isEligible()',cardField.isEligible())
      this.cardEligible = cardField.isEligible()
      if (cardField.isEligible()) {
        const nameField = cardField.NameField();
        nameField.render("#card-name-field-container");

        const numberField = cardField.NumberField();
        numberField.render("#card-number-field-container");

        const cvvField = cardField.CVVField();
        cvvField.render("#card-cvv-field-container");

        const expiryField = cardField.ExpiryField();
        expiryField.render("#card-expiry-field-container");

        // Add click listener to submit button and call the submit function on the CardField component
        document
            .getElementById("multi-card-field-button")
            .addEventListener("click", () => {
              this.paypalPaymentMode = 'CARD'
              cardField.submit().catch((error) => {
                this.showOverlay = false
                this.$emit('on-loader', false)
                if (error.message === 'Window closed for postrobot_method before response') {
                  return
                }
                this.$notify({group: 'foo', type: 'error', title: 'Error', text: error});
              });
            });
      } else {
        // Hides card fields if the merchant isn't eligible
        document.querySelector("#card-form").style = "display: none";
        this.$emit('hide-button')
      }

    },
    async getPaypalKey() {
      const {data} = await axios.get(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/company/${this.transactionDetails?.company_tsid}/country/${this.transactionDetails?.country_tsid}/paypal`)
      if (data && data.value) {
        return data.value
      }
      return null
    },
    async createOrder() {
      try {
        const {data} = await axios.post(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/paypal/order/${this.transactionTsid}`, {mode: this.paypalPaymentMode})
        return data?.value?.id
      } catch (e) {
        console.log(e)
      }
    },
    async onApprove() {
      try {
        const {data} = await axios.post(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/paypal/order/${this.transactionTsid}/capture`)
        /*this.showOverlay = false
        this.activePaymentPage = false
        this.activeSuccess = true
        this.activeTimer = true
        this.timerCount--*/
        this.$emit('on-success')
        return data?.value?.id
      } catch (e) {
        console.log(e)
      }
    },
    async createVaultSetupToken() {
      try {
        const {data} = await axios.post(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/paypal/vault/${this.transactionTsid}/setup-tokens`)
        return data?.value?.id
      } catch (e) {
        console.log(e)
      }
    },
    async createVaultPaymentToken() {
      try {
        const {data} = await axios.post(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/paypal/vault/${this.transactionTsid}/payment-tokens`)
        /*this.showOverlay = false
        this.activePaymentPage = false
        this.saveCardSuccess = true
        this.activeTimer = true
        this.timerCount--*/
        this.$emit('on-success')
        return data?.value?.id
      } catch (e) {
        console.log(e)
      }
    },
    async pay() {
      document.getElementById('multi-card-field-button').click()
    }
  },
  async mounted() {
    //paypal init
    this.transactionTsid = this.transactionDetails?.tsid
    const clientId = await this.getPaypalKey();
    const script = document.createElement('script');
    script.src = `https://www.paypal.com/sdk/js?components=buttons,card-fields&client-id=${clientId}`;
    script.addEventListener('load', this.paypalSetLoaded);
    document.body.appendChild(script);
  }
}
</script>
